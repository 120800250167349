<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-10 col-md-8 mb-2">
      <div class="row mx-0 mt-4 justify-content-center">
        <div class="col-12 mb-3 text-center medium">
          I would like to donate the following as a recurring payment:
        </div>
        <div class="col-12 mb-3 text-center"  >
          <div class="row mx-0 my-3 justify-content-center">
            <div class="col-10 p-0">
              <div class="row m-0">
                <div class="col-12 p-0 medium bigger_font">
                  <CurrencyFieldCenter placeholder="0.00" size="big" color="transparent" v-model="donationValue.amount" :currencySymbol="defaultCurrency" />
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-10 col-lg-8 p-0">
              <div class="row m-0">
                <div class="col-12 p-0 mb-3">
                  <SelectAll
                    v-model="selectedParentProject"
                    id="project"
                    :options="adminProjects"
                    placeholder="Projects"
                    displayValue="description"
                    color="gold"
                    :isSearch="true"
                    :goUp="true"
                  />
                </div>
                <div class="col-12 p-0 mb-3" v-if="Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
                  <SelectAll
                    v-model="selectedChildProject"
                    id="project"
                    :options="childProjects"
                    placeholder="Children Projects"
                    displayValue="description"
                    color="gold"
                    :autoSelect="true"
                    :isSearch="true"
                    :goUp="true"
                  />
                </div>
                <div class="col-12 p-0 mb-3">
                  <SelectAll
                    v-if="Object.keys(selectedProject).length !== 0"
                    v-model="donationTypeValue"
                    id="project"
                    :options="selectedProject.projectDonationTypeMaps"
                    placeholder="Donation Type"
                    displayValue="donationType"
                    subDisplayValue="description"
                    color="gold"
                    :autoSelect="true"
                    :goUp="true"
                  />
                  <Select
                    v-else
                    v-model="donationTypeValue"
                    id="project"
                    :options="selectedProject.projectDonationTypeMaps"
                    placeholder="Donation Type"
                    displayValue="donationType"
                    subDisplayValue="description"
                    color="gold"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Select: defineAsyncComponent(() => import('@/components/SelectProject.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    CurrencyFieldCenter: defineAsyncComponent(() => import('@/components/CurrencyFieldCenter.vue'))
  },
  name: 'Recurring SetupNewSection',
  props: ['selectedProject', 'donation', 'defaultCurrency', 'donationType'],
  emits: ['update:selectedProject', 'update:donation', 'update:donationType'],
  data () {
    return {
      selectedParentProject: {},
      selectedChildProject: {}
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    selectedProjectValue: {
      get () {
        return this.selectedProject
      },
      set (val) {
        console.log('SET val', val, val.projectID)
        if (val && val.projectID) {
          this.donationValue.projectId = val.projectID
        }
        this.$emit('update:selectedProject', val)
      }
    },
    donationValue: {
      get () {
        return this.donation
      },
      set (val) {
        this.$emit('update:donation', val)
      }
    },
    donationTypeValue: {
      get () {
        return this.donationType
      },
      set (val) {
        this.$emit('update:donationType', val)
      }
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    }
  },
  watch: {
    selectedParentProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedChildProject = {}
        this.selectedProjectValue = this.selectedParentProject
      } else {
        this.selectedProjectValue = this.selectedParentProject
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProjectValue = this.selectedChildProject
      }
    }
  }
}
</script>
<style scoped>
  .bigger_font {
    font-size: 20px;
  }
</style>
